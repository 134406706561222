<template>
  <div :class="['fieldset', { 'has-error': $v.email.$error && checkValidate }]">
    <input
      v-model="$v.email.$model"
      type="email"
      :placeholder="placeholder"
      :class="[{ round: roundBorder }]"
      @keyup="$emit('clearSubmitError', 'email')"
      @keydown.space.prevent
    >
    <template v-if="checkValidate">
      <div
        v-if="!$v.email.required && $v.email.$error"
        class="error-message"
      >
        {{ errorMessage ? errorMessage : 'Укажите свой email' }}
      </div>
      <div
        v-if="!$v.email.email && $v.email.$error"
        class="error-message"
      >
        Укажите правильный email
      </div>
      <div
        v-if="!$v.email.unUsed"
        class="error-message"
      >
        Такой email уже найден
      </div>
      <div
        v-if="!$v.email.notFoundEmail"
        class="error-message"
      >
        почта не найдена
      </div>

      <div
        v-if="!$v.email.invalidLogin"
        class="error-message"
      >
        проверьте данные
      </div>
    </template>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'FormEmailField',
  mixins: [validationMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
    submitError: {
      type: Array,
      default: () => ([]),
    },
    placeholder: {
      type: String,
      default: 'Email',
    },
    roundBorder: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: [String, null],
      default: null,
    },
  },
  data: () => ({
    checkValidate: false,
  }),
  computed: {
    email: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },

    usedEmail() {
      return this.submitError.some((e) => (e.objectField === 'email' && e.message === 'уже существует'));
    },

    notFoundEmail() {
      return this.submitError.some((e) => (e.field === 'user'));
    },

    invalidLogin() {
      return this.submitError.some((e) => (e.invalidLogin));
    },
  },

  methods: {
    validate() {
      this.$v.$touch();
      this.checkValidate = true;
      return !this.$v.email.$error;
    },
    reset() {
      this.checkValidate = false;
      this.email = '';
      this.$v.$reset();
    },
  },
  validations: {
    email: {
      required,
      email,
      unUsed() {
        return !this.usedEmail;
      },
      notFoundEmail() {
        return !this.notFoundEmail;
      },
      invalidLogin() {
        return !this.invalidLogin;
      },
    },
  },
};
</script>

<style scoped lang="scss">

.fieldset {
  position: relative;

  &.has-error input {
    border-color: #FF0040 !important;
  }

  .error-message {
    color: #FF0040;
    text-align: right;
    font-size: 14px;
    line-height: 100%;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    right: 20px;
    padding: 0 5px;
    background-color: $white-color;
  }

  .round {
    border-radius: 100px !important;
  }
}

</style>
