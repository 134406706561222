<template>
  <!-- eslint-disable max-len vue/max-len -->
  <a
    href="https://olymp.synergykids.ru/russian_9-11_class?utm_source=SiteBanner&utm_medium=site&utm_campaign={campaign_id}&utm_term=banner_shus&marketer=dvk&produkt=67018269&utm_gen=3"
    target="_blank"
    class="bottom-banner"
    @click.stop
  >
    <!-- eslint-enable -->
    <div class="bottom-banner__content">
      <h5 class="bottom-banner__title">
        <span>онлайн-олимпиада по русскому побеждай и&nbsp;получай призы!</span>
      </h5>

      <button class="bottom-banner__btn m-btn">
        участвовать
      </button>

      <div class="bottom-banner__countdown">
        <p class="bottom-banner__countdown--text">
          <span>успей зарегистрироваться до 12 марта</span>
        </p>

        <div class="bottom-banner__countdown--items">
          <div
            v-for="(item, index) of countdown"
            :key="index"
            class="bottom-banner__countdown--item"
          >
            <p>
              {{ item.value }}
            </p>
            <span>
              {{ item.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import getRemainingTime from '@/utils/remainingTime';

export default {
  name: 'BottomBanner',
  data: () => ({
    remainingTime: {},
    timer: null,
    targetDate: new Date('2025-03-13T00:00:00'),
  }),
  computed: {
    /**
     * когда нужно с периодичностью обновляемая дата
     * @returns {Date}
     */
    // targetDate() {
    //   const today = new Date();
    //
    //   const oddDate = Boolean(today.getDate() % 2);
    //
    //   const tomorrow = new Date(today);
    //   tomorrow.setDate(today.getDate() + (oddDate ? 1 : 2));
    //   tomorrow.setHours(0, 0, 0, 0);
    //
    //   return tomorrow;
    // },
    countdown() {
      return [
        {
          value: this.remainingTime.days || '00',
          text: this.getDeclension(this.remainingTime.days, ['День', 'Дня', 'Дней']),
        },
        {
          value: this.remainingTime.hours || '00',
          text: this.getDeclension(this.remainingTime.hours, ['Час', 'Часа', 'Часов']),
        },
        {
          value: this.remainingTime.minutes || '00',
          text: this.getDeclension(this.remainingTime.minutes, ['Минута', 'Минуты', 'Минут']),
        },
        {
          value: this.remainingTime.seconds || '00',
          text: this.getDeclension(this.remainingTime.seconds, ['Секунда', 'Секунды', 'Секунд']),
        },
      ];
    },
  },
  beforeMount() {
    this.remainingTime = getRemainingTime(this.targetDate);

    this.timer = setInterval(() => {
      this.remainingTime = getRemainingTime(this.targetDate);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getDeclension(number, words) {
      const cases = [2, 0, 1, 1, 1, 2];
      return words[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-banner {
  display: block;
  position: relative;
  height: calc(var(--scale) * 60);
  background-image: url("/v2/global/banner-bg.webp");
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @include media-down($size-tablet) {
    background-image: url("/v2/global/banner-bg-tablet.webp");
    height: calc(var(--scale) * 50);
  }

  @include media-down($size-mobile) {
    background-image: url("/v2/global/banner-bg-mob.webp");
  }

  * {
    font-family: 'Onest', sans-serif;
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;
    padding-left: calc(var(--scale) * 140);

    @include media-down($size-tablet) {
      padding-left: calc(var(--scale) * 27);
      padding-top: calc(var(--scale) * 2);
    }

    @include media-down($size-mobile) {
      padding-left: 0;
      padding-top: 0;
      gap: calc(var(--scale) * 13);
      margin-left: calc(-1 * var(--scale) * 4);
    }
  }

  &__title {
    max-width: calc(var(--scale) * 474);
    font-weight: 700;
    font-size: calc(var(--scale) * 30);
    line-height: 80%;
    letter-spacing: -0.02em;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      max-width: calc(var(--scale) * 314);
      font-size: calc(var(--scale) * 20);
      line-height: 90%;
    }

    @include media-down($size-mobile) {
      max-width: calc(var(--scale) * 185);
      font-size: calc(var(--scale) * 16);
    }
  }

  &__btn {
    margin-left: calc(var(--scale) * 39);
    margin-right: calc(var(--scale) * 103);
    padding: calc(var(--scale) * 12) calc(var(--scale) * 66);
    background-color: #FF7137;
    border-radius: calc(var(--scale) * 16);

    font-weight: 700;
    font-size: calc(var(--scale) * 20);
    line-height: 120%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      margin-left: calc(var(--scale) * 15);
      margin-right: calc(var(--scale) * 33);
      padding: calc(var(--scale) * 7) calc(var(--scale) * 34);
      font-size: calc(var(--scale) * 11);
      border-radius: calc(var(--scale) * 8);
    }

    @include media-down($size-mobile) {
      display: none;
    }
  }

  &__countdown {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-down($size-mobile) {
      margin-top: calc(var(--scale) * 4);
    }

    &--text {
      font-weight: 500;
      font-size: calc(var(--scale) * 13);
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.02em;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 10);
      }

      @include media-down($size-mobile) {
        font-size: calc(var(--scale) * 8);
      }
    }

    &--items {
      display: flex;
    }

    &--item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(var(--scale) * 64);

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 44);
      }

      &:not(&:last-of-type):after {
        content: '';
        display: block;
        width: calc(var(--scale) * 1);
        height: calc(var(--scale) * 20);
        position: absolute;
        top: calc(50% - calc(var(--scale) * 10));
        right: calc(-1 * var(--scale) * 0.5);
        background-color: rgba(255, 255, 255, 0.5);

        @include media-down($size-tablet) {
          height: calc(var(--scale) * 15);
        }
      }

      p {
        margin-block: calc(-1 * var(--scale) * 2);
        font-variant-numeric: tabular-nums;
        font-weight: 600;
        font-size: calc(var(--scale) * 28);
        line-height: 110%;
        letter-spacing: -0.08em;
        text-transform: uppercase;
        color: #FFFFFF;

        @include media-down($size-tablet) {
          margin-block: calc(-1 * var(--scale) * 1);
          font-size: calc(var(--scale) * 22);
        }

        @include media-down($size-mobile) {
          font-size: calc(var(--scale) * 20);
        }
      }

      span {
        font-weight: 500;
        font-size: calc(var(--scale) * 10);
        line-height: 110%;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.5);

        @include media-down($size-tablet) {
          font-size: calc(var(--scale) * 8);
        }
      }
    }
  }
}
</style>
